<template>
  <v-container
    grid-list-lg
    class="schedule-create py-0">
    <v-form
      ref="form"
      v-model="formValid"
      lazy-validation>
      <a
        v-if="isCaseSchedule"
        class="back-link"
        @click="$router.go(-1)">
        voltar para lista <v-icon small>{{ $root.icons.action.previous }}</v-icon>
      </a>
      <v-layout
        v-resize="onResize"
        class="pt-6"
        :style="setHeightScroll"
        row
        wrap>
        <v-flex
          xs12
          sm12>
          <v-radio-group
            v-model="schedule.type"
            class="tasks-radio-group"
            :rules="requiredRules"
            required
            row>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-radio
                  value="phone"
                  v-on="on">
                  <template v-slot:label>
                    <v-icon v-on="on">
                      {{ $root.icons.layout.phone }}
                    </v-icon>
                  </template>
                </v-radio>
              </template>
              <span>Ligação</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-radio
                  value="task"
                  v-on="on">
                  <template v-slot:label>
                    <v-icon v-on="on">
                      {{ $root.icons.layout.calendar }}
                    </v-icon>
                  </template>
                </v-radio>
              </template>
              <span>Tarefa</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-radio
                  value="video"
                  v-on="on">
                  <template v-slot:label>
                    <v-icon v-on="on">
                      {{ $root.icons.layout.video }}
                    </v-icon>
                  </template>
                </v-radio>
              </template>
              <span>Vídeo Conferência</span>
            </v-tooltip>
          </v-radio-group>
        </v-flex>
        <v-flex
          xs6
          sm12
          class="pt-0">
          <select-participants
            ref="participantsForm"
            class="py-0"
            label="Participantes"
            :participants="participants"
            @change="getPeoples" />

          <v-text-field
            v-model="schedule.name"
            class="px-2"
            label="Título"
            :rules="requiredRules"
            required />

          <v-text-field
            v-model="schedule.description"
            class="px-2"
            label="Descrição"
            :rules="requiredRules"
            required />
        </v-flex>
        <v-flex
          xs12
          sm12
          class="pt-0 px-0">
          <date-time-range
            ref="dateForm"
            @update="updateDateTime" />
        </v-flex>

        <v-flex
          xs12
          sm12
          class="pt-0">
          <notifications-list @change="changeNotifications" />
        </v-flex>
      </v-layout>
      <div
        v-if="isCaseSchedule"
        class="schedule-create__actions">
        <v-btn
          class="mb-2 mr-2"
          color="darkGray"
          small
          text
          @click="$router.go(-1)">
          Cancelar
        </v-btn>
        <v-btn
          class="mr-0 mb-2"
          color="primary"
          :disabled="!formValid"
          :loading="isLoading"
          small
          @click="createSchedule">
          Salvar
        </v-btn>
      </div>
    </v-form>
  </v-container>
</template>

<script>
  import validate from '@/mixins/validate'
  import masks from '@/mixins/masks'
  import resizing from '@/mixins/resizing'
  import snackbar from '@/mixins/snackbar'
  import DateTimeRange from '@/components/ui/DateTimeRange'
  import SelectParticipants from '@/components/ui/SelectParticipants'
  import NotificationsList from '@/components/ui/NotificationsList'
  import { mapGetters } from 'vuex'

  export default {
    components: { SelectParticipants, NotificationsList, DateTimeRange },
    mixins: [ validate, masks, snackbar, resizing ],
    data: () => ({
      formValid: true,
      schedule: {
        type: '',
        participants: null,
        name: '',
        description: '',
        notifications: [],
        state: 'scheduled'
      }
    }),
    computed: {
      isCaseSchedule () {
        return this.$route.name === 'CaseScheduleCreate' || this.$route.name === 'VideoCaseScheduleCreate'
      },
      ...mapGetters({
        isLoading: 'app/getLoading',
        userId: 'user/id',
        case: 'cases/record',
        participants: 'cases/participants'
      })
    },
    mounted () {
      this.showSidebar = true
    },
    beforeDestroy () {
      this.showSidebar = false
    },
    methods: {
      getPeoples (peoples) {
        this.schedule.participants = peoples
      },
      changeNotifications (lists) {
        this.schedule.notifications = lists
      },
      onDateRangeChange (range) {
        this.range = range
      },
      updateDateTime (data) {
        this.schedule = Object.assign({}, this.schedule, data)
      },
      createSchedule () {
        const participants = this.schedule.participants
        participants && !participants.includes(this.userId) && participants.push(this.userId)

        const isFormValid = this.$refs.form.validate()
        const isParticipantsValid = this.$refs.participantsForm.$children[0].validate()
        const isDateValid = this.$refs.dateForm.$children[0].validate()

        if (isFormValid && isParticipantsValid && isDateValid) {
          this.$store.commit('app/setLoading', false)
          const id = this.$uuid.v4()
          const schedule = this.schedule
          schedule.case = this.case.id
          schedule.owner = this.userId

          this.$store.dispatch('cases/createTask', { ...schedule, id: id })
            .then(this._success)
            .catch(this._failure)
        }
      },
      _success () {
        this.$store.commit('app/setLoading', false)
        this.$root.$emit('snackbar', {show: true, color: 'success', text: 'Agenda criada com sucesso'})
        this.$router.push({ name: 'CaseScheduleList' })
      },
      _failure () {
        this.$store.commit('app/setLoading', false)
        this.$root.$emit('snackbar', {show: true, time: 10000, color: 'error', text: 'houve um problema'})
      },
    }
  }
</script>

<style lang="sass">
  .schedule-create
    overflow-y: scroll

    .back-link
      background: #fff
      padding: 5px 10px
      margin-top: -5px
      margin-right: -10px

    &__actions
      width: 100%
      padding: 20px 71px 0
      bottom: 0
      position: fixed
      text-align: right
      background: #fff
</style>
