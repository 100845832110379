<template>
  <loading
    v-if="loading > 0"
    class="absolute-center" />
  <v-container
    v-else
    grid-list-lg
    class="case-schedule">
    <v-tooltip left>
      <template v-slot:activator="{ on }">
        <v-btn
          v-if="$can('CaseScheduleCreate') && tasks.length > 0"
          class="btn-fixed-bottom mb-2 mr-2"
          color="primary"
          dark
          fab
          small
          v-on="on"
          @click="create">
          <v-icon>{{ $root.icons.action.add }}</v-icon>
        </v-btn>
      </template>
      <span>Criar na agenda</span>
    </v-tooltip>
    <v-layout
      row
      wrap>
      <v-flex
        xs12
        sm12
        class="py-0">
        <v-data-table
          v-if="tasks.length > 0"
          :headers="headers"
          :items="tasks"
          hide-default-footer>
          <template
            v-slot:item="{ item }"
            class="transparent">
            <tr>
              <td class="px-2">
                <label-icon :type="item.type" />
              </td>
              <td class="px-2 text-center">
                {{ item.start }}
              </td>
              <td class="px-2 text-center">
                {{ $t('task.state.' + item.state) }}
              </td>
              <td class="text-right px-0">
                <v-btn
                  v-if="$can('guest')"
                  small
                  @click="enterVideoConference(item.id)">
                  Entrar
                </v-btn>
                <v-menu
                  v-else
                  transition="scale-transition"
                  bottom
                  right
                  offset-y
                  :disabled="setDisabled(item.state)">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      small
                      class="mr-2"
                      :disabled="setDisabled(item.state)"
                      v-on="on">
                      <v-icon color="darkGray">
                        {{ $root.icons.layout.more_y }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="openModalStart(item)">
                      <v-list-item-title>Iniciar</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="openModalComplete(item)">
                      <v-list-item-title>Finalizar</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="openModalCancel(item)">
                      <v-list-item-title>Cancelar</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="openModalPostpon(item)">
                      <v-list-item-title>Adiar</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>
          <template v-slot:pageText="props">
            {{ props.pageStart }} de {{ props.pageStop }} no total de {{ props.itemsLength }}
          </template>
        </v-data-table>
        <empty-state
          v-else
          size="75px"
          :icon="$root.icons.link.schedule"
          class="absolute-center">
          Não há nada agendado <br>
          <v-btn
            v-if="showScheduleCreateButton"
            color="accent"
            class="mt-2"
            depressed
            small
            @click="create">
            Agendar
          </v-btn>
        </empty-state>
      </v-flex>
    </v-layout>

    <modal
      btn-primary-label="Sim"
      btn-secondary-color="Não"
      :show="showModalStart"
      @close="closeModalStart"
      @submit="saveModalStart">
      <span slot="title">Iniciar</span>
      <div slot="content">
        <p class="mt-3">
          Deseja iniviar a <b>{{ scheduleSelected.name }}</b>?
        </p>
      </div>
    </modal>

    <modal
      btn-primary-label="Sim"
      btn-secondary-color="Não"
      :show="showModalComplete"
      @close="closeModalComplete"
      @submit="saveModalComplete">
      <span slot="title">Finalização</span>
      <div slot="content">
        <p class="mt-3">
          Deseja que a tarefa <b>{{ scheduleSelected.name }}</b> seja finalizada?
        </p>
      </div>
    </modal>

    <modal
      max-width="720px"
      :show="showModalPostpon"
      @close="closeModalPostpon"
      @submit="saveModalPostpon">
      <span slot="title">Adiamento</span>
      <v-form
        slot="content"
        ref="form"
        v-model="valid"
        lazy-validation>
        <v-layout
          row
          wrap>
          <v-flex
            xs12
            sm12
            class="pt-0 px-0">
            <date-time-range @update="updateDateTime" />
          </v-flex>
        </v-layout>
      </v-form>
    </modal>

    <modal
      max-width="400px"
      submit-label="Confirmar"
      :show="showModalCancel"
      @close="closeModalCancel"
      @submit="saveModalCancel">
      <span slot="title">Cancelamento</span>
      <div slot="content">
        <p class="mt-3">
          Deseja que a tarefa <b>{{ scheduleSelected.name }}</b> seja cancelada?
        </p>
      </div>
    </modal>
  </v-container>
</template>

<script>
  import LabelIcon from '@/components/ui/LabelIcon'
  import scheduleMixin from '@/mixins/schedule'
  import casesMixin from '@/mixins/cases'
  import { mapGetters } from 'vuex'

  export default {
    components: { LabelIcon },
    mixins: [ casesMixin, scheduleMixin ],
    data () {
      return {
        headers: [
          { text: 'Tipo', value: 'type' },
          { text: 'Data', value: 'date', align: 'center' },
          { text: 'Status', value: 'state', align: 'center' },
          { text: 'Ação', value: 'action', align: 'right' }
        ]
      }
    },
    computed: {
      showScheduleCreateButton () {
        const canCreate = this.$can('CaseScheduleCreate')
        const caseState = this.case && this.case.state
        return canCreate && !['accepted', 'refused'].includes(caseState)
      },
      ...mapGetters({
        tasks: 'cases/tasks'
      })
    },
    beforeMount () {
      this.$store.dispatch('cases/getTasks', this.$route.params.caseId)
    },
    methods: {
      enterVideoConference (roomId) {
        this.$store.dispatch('app/videoConference', { room: roomId, show: true })
      },
      setIconType (type) {
        switch (type) {
        case 'phone': return 'phone_in_talk'
        case 'video': return 'videocam'
        default: return 'filter_frames'
        }
      },
      setDisabled (state) {
        return !['scheduled', 'in-progress'].includes(state)
      },
      create () {
        this.$router.push({ name: 'CaseScheduleCreate' })
      }
    }
  }
</script>

<style lang="sass">
  .btn-fixed-bottom
    position: fixed
    bottom: 0
    right: 0

  .case-schedule
    height: 100%
    transform: translateX(0)
</style>

