import DateTimeRange from '@/components/ui/DateTimeRange'
import Modal from '@/components/ui/Modal'

export default {
  components: { DateTimeRange, Modal },
  data () {
    return {
      valid: true,
      showModalStart: false,
      showModalComplete: false,
      showModalPostpon: false,
      showModalCancel: false,
      scheduleSelected: {
        id: null,
        type: '',
        title: ''
      },
      defaultScheduleSelected: {
        id: null,
        type: '',
        title: ''
      },
      updateSchedule: {}
    }
  },
  methods: {
    closeModalStart () {
      this.showModalStart = false
      this._setDefaultSchedule()
    },
    closeModalComplete () {
      this.showModalComplete = false
      this._setDefaultSchedule()
    },
    openModalStart (scheduleSelected) {
      this.showModalStart = true
      this.scheduleSelected = scheduleSelected
    },
    openModalComplete (scheduleSelected) {
      this.showModalComplete = true
      this.scheduleSelected = scheduleSelected
    },
    saveModalStart () {
      this.$store.dispatch('cases/videoConferenceStart', this.scheduleSelected.id).then(() => {
        this.closeModalStart()
        this.$root.$emit('snackbar', {show: true, color: 'success', text: 'A video-conferência foi iniciada'})
      })
    },
    saveModalComplete () {
      this.$store.dispatch('cases/videoConferenceComplete', this.scheduleSelected.id).then(() => {
        this.closeModalComplete()
        this.$root.$emit('snackbar', {show: true, color: 'success', text: 'A Tarefa foi finalizada'})
      })
    },
    closeModalPostpon () {
      this.showModalPostpon = false
      this._setDefaultSchedule()
    },
    openModalPostpon () {
      this.showModalPostpon = true
    },
    saveModalPostpon () {
      if (this.$refs.form.validate()) {
        this.closeModalPostpon()
        this.$root.$emit('snackbar', {show: true, color: 'success', text: 'A Tarefa foi adiada'})
      }
    },
    updateDateTime (data) {
      this.updateSchedule = Object.assign({}, this.schedule, data)
      console.log('update schedule', this.schedule)
    },
    openModalCancel (scheduleSelected) {
      this.showModalCancel = true
      this.scheduleSelected = scheduleSelected
    },
    closeModalCancel () {
      this.showModalCancel = false
      this._setDefaultSchedule()
    },
    saveModalCancel () {
      this.closeModalCancel()
      this.$root.$emit('snackbar', {show: true, color: 'success', text: 'A Tarefa foi cancelada'})
    },
    _setDefaultSchedule () {
      this.scheduleSelected = this.defaultScheduleSelected
    }
  }
}
