export default {

  data: () => ({
    mask: {
      cnpj: '##.###.###/####-##',
      cep: '##.###-###',
      cpf: '###.###.###-##',
      propertyRegistration: '##.##.####.####.####',
      date: '##/##/####',
      phone: '+55 ## ####-####',
      celPhone: '+55 ## #####-####'
    },
    money: {
      decimal: ',',
      thousands: '.',
      // prefix: 'R$ ',
      prefix: '',
      suffix: '',
      precision: 2,
      masked: false /* doesn't work with directive */
    }
  })
}
