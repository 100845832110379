<template>
  <v-container
    grid-list-lg
    class="py-0 px-0">
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation>
      <v-layout
        row
        wrap>
        <v-flex
          xs6
          sm3
          class="pt-0 forced-flex">
          <v-menu
            ref="menuStart"
            v-model="schedule.startDate.menu"
            transition="scale-transition"
            min-width="290px"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="startDateFormatted"
            offset-y>
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="startDateFormatted"
                label="Data inicial"
                :prepend-icon="$root.icons.layout.calendar"
                :rules="requiredRules"
                required
                readonly
                v-on="on" />
            </template>

            <v-date-picker
              v-model="schedule.startDate.date"
              locale="pt-br"
              :min="minStartDate"
              scrollable
              @input="submitStartDate" />
          </v-menu>
        </v-flex>
        <v-flex
          xs4
          sm2
          class="pt-0 forced-flex">
          <v-select
            v-model="schedule.startDate.hour"
            item-text="value"
            label="Horário"
            :items="startTimeHour"
            :rules="requiredRules"
            required
            @change="changeStartTime" />
        </v-flex>
        <v-flex
          xs4
          sm2
          class="pt-0 ml-2 forced-flex">
          <v-select
            v-model="schedule.endDate.hour"
            item-text="value"
            label="Duração"
            :items="endTimeHour"
            :rules="requiredRules"
            required
            @change="changeEndTime" />
        </v-flex>
      </v-layout>
    </v-form>
  </v-container>
</template>

<script>
  import validate from '@/mixins/validate'
  import { timeHour, timeDuration } from '@/utils'

  export default {
    mixins: [ validate ],
    data () {
      return {
        valid: true,
        startDateFormatted: '',
        endDateFormatted: '',
        schedule: {
          startDate: {
            date: null,
            menu: false,
            hour: ''
          },
          endDate: {
            date: null,
            menu: false,
            hour: ''
          }
        },
        newTime: {
          start: {
            date: '',
            hour: ''
          },
          end: {
            date: '',
            hour: ''
          }
        },
        startTimeHour: [],
        endTimeHour: [],
        minEndDate: '',
        minStartDate: ''
      }
    },
    mounted () {
      this.startTimeHour = this.startTimeHour.concat(timeHour)
      this.endTimeHour = this.endTimeHour.concat(timeDuration)

      this.setMinStartDate()
    },
    methods: {
      submitStartDate (date) {
        this.$refs.menuStart.save(date)
        this.minEndDate = date

        this.$nextTick(() => {
          this.newTime.start.date = this.startDateFormatted = this.formatDate(date)
        })

        if (this.schedule.endDate.date !== null) {
          this.schedule.endDate.date = date
        }
        this.validDate(date)
      },
      submitEndDate (date) {
        this.$refs.menuEnd.save(date)
        this.$nextTick(() => {
          this.newTime.end.date = this.endDateFormatted = this.formatDate(date)
        })
        this.validDate(date)
      },
      validTimeHour () {
        this.setDefaultTimeHour()
        this.setDisable()

        this.$nextTick(() => {
          let getIndexStart = this.startTimeHour.map(e => e.value).indexOf(this.schedule.startDate.hour)
          let getIndexEnd = this.endTimeHour.map(e => e.value).indexOf(this.schedule.endDate.hour)
          if (this.schedule.endDate.hour !== '' && getIndexStart > getIndexEnd) {
            this.schedule.endDate.hour = ''
          }
        })
      },
      validDate (endDate) {
        endDate === this.schedule.startDate.date ? this.validTimeHour() : this.setDefaultTimeHour()
        this.emitEvent()
      },
      setDisable () {
        let getIndex = this.endTimeHour.map(e => e.value).indexOf(this.schedule.startDate.hour)

        this.endTimeHour.map((item, i) => {
          if (i < getIndex) { item.disabled = true }
        })
      },
      compareTimeHour () {
        let getIndexStart = this.startTimeHour.map(e => e.value).indexOf(this.schedule.startDate.hour)
        let getIndexEnd = this.endTimeHour.map(e => e.value).indexOf(this.schedule.endDate.hour)

        if (this.schedule.endDate.hour !== '' && getIndexStart > getIndexEnd) {
          this.schedule.endDate.hour = ''
        }
      },
      setDefaultTimeHour () {
        this.endTimeHour.map((item) => { item.disabled = false })
      },
      changeStartTime (time) {
        const date = this.newTime.start.date.split('/').reverse().join('/')

        this.newTime.start = new Date(`${date}, ${time}`)
        this.validDate(this.schedule.endDate.date)
      },
      changeEndTime (time) {
        const start = this.newTime.start
        let minutes = parseInt(time.split(':')[1])

        if (minutes === 0) { minutes = 60 }

        const endTime = new Date(start.getTime() + minutes * 60000).toLocaleTimeString()
        const startDate = start.toDateString()

        this.newTime.end = new Date(`${startDate} ${endTime}`)
        this.validDate(this.schedule.endDate.date)
      },
      emitEvent () {
        this.$emit('update', this.newTime)
      },
      formatDate (date) {
        // if (!date) return null

        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      },
      setMinStartDate () {
        let date = new Date().toLocaleDateString('pt-br')
        let [month, day, year] = date.split('/')

        this.minStartDate = `${year}-${day.padStart(2, '0')}-${month.padStart(2, '0')}`
      }
    }
  }
</script>
