<template>
  <img
    v-if="image"
    :src="image"
    :alt="name">

  <gravatar
    v-else
    :default-img="defaultImg"
    :email="email"
    :alt="name" />
</template>
<script>
  import Gravatar from 'vue-gravatar'
  export default {
    components: { Gravatar },
    props: {
      email: {
        type: String,
        require: true,
        default: ''
      },
      name: {
        type: String,
        require: true,
        default: ''
      },
      image: {
        type: String,
        require: true,
        default: ''
      },
      defaultImg: {
        type: String,
        default: 'mm'
      }
    }
  }
</script>
