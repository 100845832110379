<template>
  <v-container
    grid-list-md
    class="notifications-list px-0 py-0">
    <v-layout
      v-for="(list, index) in lists"
      :key="index"
      row>
      <v-flex
        xs4>
        <v-select
          v-model="list.channel"
          :items="defaultList.channels"
          item-text="label"
          label="Receber por"
          :rules="requiredRules"
          required
          @change="changeChannel" />
      </v-flex>
      <v-flex
        xs4>
        <v-select
          v-model="list.time"
          :items="defaultList.times"
          item-text="label"
          label="Quando"
          :rules="requiredRules"
          required
          @change="changeTime" />
      </v-flex>
      <v-flex
        xs4>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              fab
              x-small
              class="elevation-0 mt-4 ml-3"
              v-on="on"
              @click="remove(list)">
              <v-icon
                x-small
                dark
                color="error">
                {{ $root.icons.action.remove }}
              </v-icon>
            </v-btn>
          </template>
          <span>Remover</span>
        </v-tooltip>
      </v-flex>
    </v-layout>
    <v-tooltip right>
      <template v-slot:activator="{ on }">
        <v-btn
          v-if="showAddAction"
          class="add-notification ml-0"
          color="accent"
          :disabled="disabled"
          small
          text
          fab
          v-on="on"
          @click="add">
          <v-icon color="accent">
            {{ $root.icons.layout.notification }}
          </v-icon>
        </v-btn>
      </template>
      <span>Crie uma notificação</span>
    </v-tooltip>
  </v-container>
</template>

<script>
  import validate from '@/mixins/validate'

  export default {
    mixins: [ validate ],
    data () {
      return {
        lists: [],
        maxList: 5,
        disabled: false,
        defaultList: {
          channels: [
            { label: 'E-mail', value: 'email' },
            { label: 'SMS', value: 'sms' }
          ],
          times: [
            {
              label: 'no momento',
              value: '0 minutes'
            },
            {
              label: '5 minutos',
              value: '5 minutes'
            },
            {
              label: '10 minutos',
              value: '10 minutes'
            },
            {
              label: '15 minutos',
              value: '15 minutes'
            },
            {
              label: '30 minutos',
              value: '30 minutes'
            },
            {
              label: '60 minutos',
              value: '60 minutes'
            }
          ]
        }
      }
    },
    computed: {
      showAddAction () {
        return this.lists.length < this.maxList
      }
    },
    methods: {
      remove (option) {
        this.lists = this.lists.filter(record => record !== option)
        this.lists.length > 0 ? this._setDisabledButton() : this.activedButton()
      },
      add () {
        this.disabled = true
        this.lists.push({})
      },
      activedButton () {
        this.disabled = false
      },
      changeChannel () {
        this._setDisabledButton()
        this.$emit('change', this.lists)
      },
      changeTime () {
        this._setDisabledButton()
        this.$emit('change', this.lists)
      },
      _setDisabledButton () {
        let lastItem = this.lists.slice(-1)[0]
        this.disabled = Object.keys(lastItem).length < 1
      }
    }
  }
</script>

<style lang="sass">
  .add-notification
    bottom: 7px
    position: fixed
    border: 1px solid $accent
    cursor: pointer
    z-index: 10

    &:hover
      opacity: .8

    &:active
      z-index: 10
</style>
